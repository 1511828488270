
export default {
  inheritAttrs: false,

  props: {
    inline: {
      type: Boolean,
      default: false,
    },

    dateFrom: {
      type: String,
      required: true,
    },

    dateTo: {
      type: String,
      required: true,
    },

    periodType: {
      type: String, // "YEARLY", "MONTHLY", "QUARTERLY"
      required: true,
    },
  },

  data() {
    return {
      node: undefined,
      dropdownItems: ["MONTHLY", "QUARTERLY", "YEARLY"],

      dateFromInternal: this.dateFrom,
      dateToInternal: this.dateTo,
      periodTypeInternal: this.periodType,
    };
  },

  mounted() {
    if (!this.inline) {
      this.$nextTick(() => {
        this.setPosition();
      });
    }
  },

  methods: {
    // Set the position aligned to the right of the target
    setPosition() {
      const node = this.$refs.menu;
      const rect = node.getBoundingClientRect();

      node.style.left = `calc((${rect.width}px - 2rem) * -1)`; // Container width + padding + margin
      node.style.top = `0.5rem`; // Icon height + margin + padding
    },

    getLabelForPeriodType(periodType) {
      switch (periodType) {
        case "YEARLY":
          return this.$t("PORTFOLIO_GRAPH_EDIT_MENU_PERIOD_TYPE_YEAR");
        case "MONTHLY":
          return this.$t("PORTFOLIO_GRAPH_EDIT_MENU_PERIOD_TYPE_MONTH");
        case "QUARTERLY":
          return this.$t("PORTFOLIO_GRAPH_EDIT_MENU_PERIOD_TYPE_QUARTER");
        default:
          return "";
      }
    },

    clickSave() {
      this.$emit("update", {
        dateFrom: this.dateFromInternal,
        dateTo: this.dateToInternal,
        periodType: this.periodTypeInternal,
      });

      this.$emit("close");
    },

    clickClose() {
      this.$emit("close");
    },
  },
};
